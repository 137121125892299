import RevFFrame from "../static/images/revf/rev_f_software.webp";
import EcoFrame from "../static/images/cn_amp/plugin_ui.webp";

export const PRODUCT_NAMES = {
  "ecocide-amplifier-suite": "Tone Suite - Ecocide",
  "rev-f-amplifier-suite": "Amp Suite - Rev F",
  "namu-amplifier-suite": "NAM Universal",
  "forte-boost-22": "Forte Boost 22",
  "x-drive-pedal": "X-Drive",
  "resington-shoeless-drive": "Resington Shoeless Drive",
  "cab-suite-412-os-lite": "Cab Suite 412 OS Lite"
}

export const PRODUCT_IMAGES = {
  "rev-f-amplifier-suite": RevFFrame,
  "ecocide-amplifier-suite": EcoFrame
}