import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";

import { startCheckout } from "../util/api/paymentApi";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../actions/notificationActions";
import AccountDialog from "./AccountDialog";
import ProductDownload from "./ProductDownload";

const BuyButton = ({ product, sx = {}, size = "standard" }) => {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);

  const dispatch = useDispatch();

  const state = useSelector((state) => state)

  const handleClick = () => {
    // if (!hasToken()) {
    //   setAccountDialogOpen(true);
    //   return;
    // }

    if (disabled)
      return;

    if (product?.owned === true) {
      setDownloadOpen(true);
      return;
    }

    setLoading(true)
    setDisabled(true)
    const priceID = product?.default_price?.id;

    const products = [
      {
        "price": priceID,
      }
    ]

    const email = state?.token?.data?.username

    startCheckout(products, email, window.location.pathname).then((res) => {
      window.location.href = res.data.url
    }).catch((e) => {
      setLoading(false)
      setDisabled(false)
      dispatch(setError(e.response?.data?.detail))
    })
  }

  return <>
    <AccountDialog open={accountDialogOpen} onClose={() => { setAccountDialogOpen(false) }} />
    <ProductDownload open={downloadOpen} onClose={() => setDownloadOpen(false)} productID={product?.metadata?.product_id} />
    <Button
      disabled={disabled}
      onClick={handleClick}
      color="primary"
      variant="contained"
      sx={{
        background: "white",
        color: "black",
        ...sx,
      }}
      size={size}
    >
      {loading && <CircularProgress size="23px" sx={{ color: "#000" }} />}
      {!loading && !disabled && product?.owned !== true && "Buy Now"}
      {!loading && product?.owned === true && "Download"}
    </Button>
  </>
}

export default BuyButton;
