import axios from "axios";

export const getAllProducts = async () => {
    return await axios.get(`/api/product/all`)
}

export const getProduct = async (id: string) => {
    return axios.get(`/api/product/${id}`)
}

