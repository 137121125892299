import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import PageNotFound from "./pages/PageNotFound/PageNotFound.js";
import LoginPage from "./pages/Login";
import Home from "./pages/Home/Home";
import Logout from "./pages/Logout";
import AccountCreationPage from "./pages/CreateAccount";
import RegisterSuccessPage from "./pages/RegisterSuccess";
import EmailConfirmPage from "./pages/ConfirmEmail";
import StorePage from "./pages/Store";
import RectifierPage from "./pages/Products/NeuralSoloHead/old/index_old";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SupportPage from "./pages/Support";
import AccountPage from "./pages/Account";
import ComingSoon from "./pages/ComingSoon";
import { LoggedIn, LoggedOut } from "./components/AccessControl";
import AboutPage from "./pages/About";
import PaymentSuccess from "./pages/PaymentSuccess";
import DownloadProviderPage from "./pages/DownloadProvider";
import KeyClaimPage from "./pages/KeyClaim";
import RevFAmplifierSuitePage from "./pages/Products/Rev F";
import EcocideAmplifierSuitePage from "./pages/Products/EcocideAmp";
import RefundPage from "./pages/Refund";

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/software" element={<StorePage />} />
        <Route path="/store" element={<StorePage />} />
        <Route path="/refund" element={<RefundPage />} />
        <Route path="/product/neural-solo-head" element={<Home />} />
        <Route path="/product/rev-f-amplifier-suite" element={<RevFAmplifierSuitePage />} />
        <Route path="/product/ecocide-amplifier-suite" element={<EcocideAmplifierSuitePage />} />
        <Route path="/confirmEmail" element={<EmailConfirmPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registerSuccess" element={<RegisterSuccessPage />} />
        <Route path="/register" element={<AccountCreationPage />} />
        <Route path="/" element={<Home />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:resetKey" element={<ResetPassword />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/contact" element={<SupportPage />} />
        <Route path="/claim" element={<KeyClaimPage />} />
        {/* <Route path="/about" element={<AboutPage />} /> */}
        <Route path="/paymentSuccess/:sessionID" element={<PaymentSuccess />} />
        <Route path="/account" element={<><LoggedIn><AccountPage /></LoggedIn><LoggedOut><LoginPage /></LoggedOut></>} />
        <Route path="/downloads/:os/:productID" element={<DownloadProviderPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* <Routes>
                <Route path="/terms" element={<TermsOfService />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/support" element={<SupportPage />} />
                <Route path="/contact" element={<SupportPage />} />
                <Route path="*" element={<ComingSoon />} />
            </Routes> */}
    </BrowserRouter>
  );
};

export default AppRouter;
