import { useEffect, useState } from "react";

import { useDispatch, useSelector } from 'react-redux';
import Currency from 'react-currency-formatter';
import TopBar from "../../components/TopBar";
import { Box, Card, CardContent, Grid, Button, Typography, CardMedia, Skeleton, CircularProgress } from "@mui/material";

import BottomBar from "../../components/BottomBar";
import { setError } from "../../actions/notificationActions";
import { getAllProducts } from "../../util/api/productApi";
import { startCheckout } from "../../util/api/paymentApi";
import { LoggedIn, LoggedOut } from "../../components/AccessControl";
import EmailHarvesting from "../../components/EmailHarvesting";
import ProductDownload from "../../components/ProductDownload";
import AccountDialog from "../../components/AccountDialog";
import BuyButton from "../../components/BuyButton";
import CouponAlert from "../../components/CouponAlert";

import CaboOS412 from "../../static/images/cabos/Cab 412 OS.webp"
import XDriveFrame from "../../static/images/xdrive/xdrive_frame.webp"
import CleanBoostFrame from "../../static/images/cleanboost/cleanboost_frame.webp"
import ShoelessFrame from "../../static/images/shoeless/Shoeless Frame.webp"
import RevFFrame from "../../static/images/revf/rev_f_software.webp";
import EcoFrame from "../../static/images/cn_amp/plugin_ui.webp";
import NAMUFrame from "../../static/images/NAMU/namu_frame.webp"

import { Helmet } from 'react-helmet';

const image_cache = {
  "rev-f-amplifier-suite": RevFFrame,
  "ecocide-amplifier-suite": EcoFrame
}


const FreewareProductCard = ({ productID, productImage, productTitle, onDownload, productDescription, downloadDisabled }) => {
  return <Grid item xs={12} md={6} lg={4} sx={{ flexGrow: "1" }}>
    {/* <AccountDialog open={accountDialogOpen} onClose={() => { setAccountDialogOpen(false) }} /> */}

    <Card variant="outlined" sx={{ position: "relative", width: "100%", borderRadius: 2, display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" }}>

      <Box >

        <CardContent justifyContent="center" display="flex">
          <Typography textAlign="center" variant="h1" sx={{ fontWeight: 500, fontSize: { xs: "1.5rem !important", md: "2.4rem !important" } }}>{productTitle}</Typography>
          <CardMedia
            component="img"
            sx={{ justifyContent: "center", alignItems: "center", display: "block", width: "100%", p: 0, ml: "auto", mr: "auto" }}
            image={productImage}
            title={productTitle}
            alt={productTitle}
          />
          <Typography sx={{ ml: "auto", mr: "auto", mt: 2, fontSize: 20 }} textAlign="center" variant="body1">{productDescription}</Typography>
          <Typography sx={{ ml: "auto", mr: "auto", mt: 1, mb: 1, fontSize: 14 }} textAlign="center" variant="body1">Available {downloadDisabled ? "soon" : ""} in VST3, AU and AAX plugin formats.</Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              disabled={downloadDisabled}
              onClick={onDownload}
              color="primary"
              variant="contained"
              sx={{
                background: "white",
                color: "black",
                width: "100%",
                borderRadius: "20px"
              }}
              size={"large"}
            >
              {downloadDisabled ? "Available Soon" : "Download"}
            </Button>
          </Box>
        </CardContent>
      </Box>
    </Card>
  </Grid >
}

const StorePage = ({ useHelmet = true }) => {
  const [products, setProducts] = useState([])
  const [shouldScroll, setShouldScroll] = useState(true);
  const [loading, setLoading] = useState(true)
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [downloadID, setDownloadID] = useState(undefined);

  const dispatch = useDispatch()

  const state = useSelector((state) => state)

  const fetchProducts = () => {
    getAllProducts().then((res) => {
      setLoading(false)
      setProducts(res.data)
    }).catch(() => {
      setLoading(false)
      dispatch(setError("Could not retrieve products"))
    })
  }

  useEffect(() => {
    fetchProducts()
    // eslint-disable-next-line
  }, [])

  const handleCheckout = (priceID) => {
    setCheckoutLoading(true)

    const products = [
      {
        "price": priceID,
      }
    ]

    const email = state?.token?.data?.username

    startCheckout(products, email, "/store").then((res) => {
      window.location.href = res.data.url
    }).catch((e) => {
      setCheckoutLoading(false)
      dispatch(setError(e.response?.data?.detail))
    })
  }

  useEffect(() => {
    if (shouldScroll) {
      setTimeout(() => {
        if (shouldScroll) {
          const element = document.getElementById(window.location.hash.replace("#", ""));
          const y = element?.getBoundingClientRect().top;
          element?.scrollIntoView({ behavior: "smooth" })
        }
      }, 500);
    }

  }, [shouldScroll]);

  const handleCheckoutNotLoggedIn = () => {
    setAccountDialogOpen(true)
  }

  const ProductCard = ({ product }) => {
    return <Grid item xs={12} md={6} lg={4} sx={{ height: "100%", flexGrow: 1 }}>
      <AccountDialog open={accountDialogOpen} onClose={() => { setAccountDialogOpen(false) }} />

      <Card sx={{ position: "relative", width: "100%", borderRadius: 2, display: "flex", flexDirection: "column", justifyContent: "center" }}>

        {product?.owned === true && <Typography variant="caption" sx={{
          background: "white",
          color: "#1a1a1a",
          p: 1,
          pt: 0,
          pb: 0,
          borderRadius: 2,
          position: "absolute",
          top: 10,
          right: 20,
          fontSize: "16px"
        }}>
          Purchased
        </Typography>}
        <Box >

          <CardContent justifyContent="center" display="flex" sx={{ flexGrow: 1 }}>
            <Typography textAlign="center" variant="h1" sx={{ fontWeight: 500, fontSize: { xs: "1.5rem !important", md: "2.4rem !important" } }}>{product.name}</Typography>
            <CardMedia
              fetchpriority="high"
              component="img"
              sx={{ justifyContent: "center", alignItems: "center", display: "block", width: "100%", p: 0, ml: "auto", mr: "auto" }}
              image={image_cache[product?.metadata?.product_id]}
              title={product?.name}
              alt={product?.name}
            />

            <Typography sx={{ borderRadius: 1, ml: "auto", mr: "auto", mt: 2, background: "#fff", color: "#000", p: 1, width: "50%" }} textAlign="center" variant="h4">
              <b>
                <Currency
                  quantity={product?.default_price?.unit_amount / 100}
                  currency={product?.default_price?.currency}
                  decimal=","
                />
              </b>
            </Typography>
            <Typography sx={{ ml: "auto", mr: "auto", mt: 2, fontSize: 20 }} textAlign="center" variant="body1">{product.description}</Typography>
            {/* <Typography sx={{ ml: "auto", mr: "auto", mt: 1, mb: 1, fontSize: 14 }} textAlign="center" variant="body1">Available in VST3, AU, AAX and Standalone plugin formats.</Typography> */}
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
              <Button href={`/product/${product?.metadata?.product_id}`} sx={{ width: "50%", ml: 0, mr: "auto" }}>Learn More</Button>
              <Button onClick={() => setDownloadID(product?.metadata?.product_id)} sx={{ width: "50%", mr: 0, ml: "auto" }}>Try it</Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <BuyButton product={product} sx={{ width: "100%", borderRadius: "20px" }} />
            </Box>
          </CardContent>
        </Box>
      </Card>
    </Grid >
  }



  const LoadingCard = () => {
    return <Grid item xs={12} md={6} lg={4}>
      <Card sx={{ width: "100%", borderRadius: 5 }}>
        <Skeleton variant="rectangular" sx={{ height: 500 }} />
        <Box >
          <CardContent>
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="80%" />
              <Skeleton width="60%" />
            </Box>
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="80%" />
              <Skeleton width="60%" />
            </Box>
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="80%" />
              <Skeleton width="60%" />
            </Box>

          </CardContent>
        </Box>
      </Card>
    </Grid >
  }


  return <div style={{ display: "flex", flexDirection: "column", background: "#fff" }}>

    {useHelmet && <Helmet>
      <title>WaveMind Audio | Software</title>
      <link rel="canonical" href="https://wavemind.net/software" />
    </Helmet>}

    <TopBar menuValue="1" />
    {/* <CouponAlert /> */}
    <LoggedIn>
      <ProductDownload open={downloadID !== undefined} onClose={() => setDownloadID(undefined)} productID={downloadID} />
    </LoggedIn>
    <LoggedOut>
      <ProductDownload requireEmail showSocialMedia open={downloadID !== undefined} onClose={() => setDownloadID(undefined)} productID={downloadID} />
    </LoggedOut>
    <Grid container justifyContent={"center"} sx={{ padding: { md: 3, xs: 1 }, pt: 3, flex: 1000 }}>
      <Grid item xs={12} md={10} lg={10} sx={{ mb: 4 }}>
        <Typography textAlign="left" sx={{ color: "black", fontSize: { xs: "1.5rem !important", md: "2.4rem !important" } }} variant="h1">Plugin Store</Typography>
        <Grid container spacing={3} justifyContent={"center"}>
          {loading && [...Array(3)].map((_, i) => {
            return <LoadingCard />
          })}
          {products.map((prod) => {
            return <ProductCard product={prod} />
          })}
        </Grid>
      </Grid>


      <Grid item xs={12} md={10} lg={10} sx={{ mb: 4 }}>
        <Box id="free" sx={{ height: { xs: "30px", md: "60px" } }} />
        <Typography variant="h1" textAlign="left" sx={{ fontSize: { xs: "1.5rem !important", md: "2.4rem !important" }, mt: "30px", color: "black", mb: "20px" }} >Free Plugins</Typography>
        <Grid container spacing={3} justifyContent={"center"} sx={{ flexGrow: 1 }}>
          <FreewareProductCard onDownload={() => { setDownloadID("namu-amplifier-suite") }} productImage={NAMUFrame} productTitle="NAM Universal" productDescription={<>The ultimate NAM loader.<br />A colaboration with <a style={{ color: "#e45a4d" }} href="https://www.youtube.com/@theampsimuniverse">@The Amp Sim Universe</a></>} />

          <FreewareProductCard sx={{ fontSize: "10px" }} onDownload={() => { setDownloadID("resington-shoeless-drive") }} productImage={ShoelessFrame} productTitle="Shoeless Drive" productDescription={<>Less Shoe, more Drive. <br />A colaboration with <a style={{ color: "#e45a4d" }} href="https://www.youtube.com/@resington">@Resington</a></>} />
          {/* <FreewareProductCard sx={{ fontSize: "10px" }} downloadDisabled onDownload={() => { }} productImage={ShoelessFrameDisabled} productTitle="Coming Soon" productDescription="Stay Tuned..." /> */}

          <FreewareProductCard onDownload={() => { setDownloadID("forte-boost-22") }} productImage={CleanBoostFrame} productTitle="Forte Boost 22" productDescription={"22 dB boost within a single knob."} />
          <FreewareProductCard onDownload={() => { setDownloadID("x-drive-pedal") }} productImage={XDriveFrame} productTitle="X-Drive" productDescription={"A classic drive with an edge."} />

          {/* <FreewareProductCard onDownload={() => { setDownloadID("cab-suite-412-os-lite") }} productImage={CaboOS412} productTitle="Cab 412 OS - Lite" productDescription={"Oversized serving of tone."} /> */}
        </Grid>
      </Grid>
    </Grid >
    <EmailHarvesting />
    <BottomBar />
  </div >
}

export default StorePage;
